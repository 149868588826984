import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pipe,
  Pump,
  Pond,
  ValveConnect,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowCustom,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Statistic,
  Info,
  Alarms,
  PLCStateRouter,
  PLCColorRouter,
  MobileStatTable,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  ValvePID,
  VFDPumpControl,
  MultiSetpointControl,
  InfoButton,
} from 'isno/lib/components/controls';

import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import PLC from 'isno/lib/static/images/PLC.png';
import { fetchLocations, setIOValue, fetchPLCs } from '../actions';
import LoadingScreen from './LoadingScreen';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow_rate',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  dischargeWaterPressure: 'discharge_water_pressure',
  // Pump Data
  speed: 'speed',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  suctionWaterPressure: 'suction_water_pressure',
  // General
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
  autoManual: 'auto_manual',
  manualSetpoint: 'manual_setpoint',
  autoSetpoint: 'auto_setpoint',
  autoMode: 'auto_mode',
  manualMode: 'manual_mode',
};

function MainScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      // There are only 2 locations and 2 PLCS so we can fetch them all at once
      const promises = [
        props.fetchLocations(),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before setting 'initRequestsComplete' to true. We use
    // this to make sure we have all of the neccesary data before generating our map.
    props.fetchLocations();
    props.fetchPLCs();
    oneSecondInterval();

    return () => {
      clearTimeout(oneSecondIntervalId);
    };
  }, []);

  const pumpMainStatusKey = {
    0: 'READY',
    1: 'RUN',
    2: 'Switch',
    3: 'High Temp',
    4: 'Low Level',
    5: 'Run Fail',
    6: 'Disabled',
    7: 'Comm',
    8: 'Local',
    9: 'Fault',
    10: 'Suction',
    11: 'LEAD',
    12: 'E-Stop',
  };

  const pumpMainStatusColorKey = {
    0: `${theme.onGreen}`,
    1: `${theme.onGreen}`,
    2: `${theme.alarm}`,
    3: `${theme.alarm}`,
    4: `${theme.alarm}`,
    5: `${theme.alarm}`,
    6: `${theme.alarm}`,
    7: `${theme.alarm}`,
    8: `${theme.alarm}`,
    9: `${theme.alarm}`,
    10: `${theme.alarm}`,
    11: `${theme.onGreen}`,
    12: `${theme.alarm}`,
  };

  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  const locationEquipment2 = props.locations?.locations?.[2]?.equipment;
  // if (!props.equipment) { return <LoadingScreen />; }
  if (!locationEquipment2 || !locationEquipment1) { return <LoadingScreen />; }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label={language.booster}>
            <InfoControlRow>
              <MobileStatTable
                stats={[
                  {
                    description: language.dischargeWaterPressure,
                    label: locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units,
                    stat: locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.value,
                  },
                  {
                    description: language.flowMeter,
                    label: locationEquipment1?.Other?.ioValues?.water_flow?.ioValueType?.units,
                    stat: locationEquipment1?.Other?.ioValues?.water_flow?.value,
                  },
                  {
                    description: language.suctionWaterPressure,
                    label: locationEquipment1?.Other?.ioValues?.suction_pressure?.ioValueType?.units,
                    stat: locationEquipment1?.Other?.ioValues?.suction_pressure?.value,
                    alertText: locationEquipment1?.Other?.ioValues.low_suction_pressure?.value ? language.lowSuctionPressure : null,
                  },
                  {
                    description: language.backPressure,
                    label: locationEquipment1?.Other?.ioValues?.back_pressure?.ioValueType?.units,
                    stat: locationEquipment1?.Other?.ioValues?.back_pressure?.value,
                  },
                ]}
              />
              <MultiSetpointControl
                title="CV1 PID"
                pGainLabel="Back P-Gain SP"
                iGainLabel="Back I-Gain SP"
                pGainLabel2="Hill P-Gain SP"
                iGainLabel2="Hill I-Gain SP"
                modeOne={`${language.hill}`}
                modeTwo={`${language.back}`}
                // settings
                pidData={locationEquipment1?.['CV1 PID']}
                IOValueIDs={[
                  `${locationEquipment1?.['CV1 PID']?.ioValues?.hill_auto_setpoint?.id}`,
                  `${locationEquipment1?.['CV1 PID']?.ioValues?.back_auto_setpoint?.id}`,
                  `${locationEquipment1?.['CV1 PID']?.ioValues?.manual_setpoint?.id}`,
                ]}
                statistics={[
                  `${locationEquipment1?.['CV1 PID']?.ioValues?.hill_auto_setpoint.value}`,
                  `${locationEquipment1?.['CV1 PID']?.ioValues?.back_auto_setpoint.value}`,
                  `${locationEquipment1?.['CV1 PID']?.ioValues?.manual_setpoint.value}`,
                ]}
                labels={[
                  `${language.autoHillSetpoint}: `,
                  `${language.autoBackSetpoint}: `,
                  `${language.manualSetpoint}: `,
                ]}
                units={[
                  locationEquipment1?.['CV1 PID']?.ioValues?.hill_auto_setpoint?.ioValueType?.units,
                  locationEquipment1?.['CV1 PID']?.ioValues?.back_auto_setpoint?.ioValueType?.units,
                  locationEquipment1?.['CV1 PID']?.ioValues?.manual_setpoint?.ioValueType?.units,
                ]}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  pressureMode: 'hill_back',
                  pGainSetpoint: 'back_pgain_setpoint',
                  iGainSetpoint: 'back_igain_setpoint',
                  pGainSetpoint2: 'hill_pgain_setpoint',
                  iGainSetpoint2: 'hill_igain_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                buttons
              />
              <Info
                title={language.instrumentAir}
                statistic={locationEquipment1?.IA?.ioValues?.run_time?.value}
                label={locationEquipment1?.IA?.ioValues?.run_time?.ioValueType?.units}
                alertText={locationEquipment1?.IA?.ioValues?.low_air_pressure?.value ? language.lowAirPressure : ''}
                alertTop="50px"
                alertLeft="-8px"
              />
              <Info
                title={language.buildingTemp}
                statistic={locationEquipment1?.Other?.ioValues?.building_temperature?.value}
                label={locationEquipment1?.Other?.ioValues?.building_temperature?.ioValueType?.units}
                precision={1}
                alertText={locationEquipment1?.Other?.ioValues?.low_building_temperature?.value ? language.lowTemp : ''}
                alertTop="-30px"
                alertLeft="18px"
              />
              <InfoButton
                title={language.tripVolume}
                statistic={locationEquipment1?.FT?.ioValues?.trip_volume?.value}
                label={locationEquipment1?.FT?.ioValues?.trip_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment1?.FT?.ioValues?.trip_volume_reset?.id}
                buttonText={language.reset}
                setIOValue={props.setIOValue}
              />
              <InfoButton
                title={language.totalVolume}
                statistic={locationEquipment1?.FT?.ioValues?.total_volume?.value}
                label={locationEquipment1?.FT?.ioValues?.total_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment1?.FT?.ioValues?.total_volume_reset?.id}
                buttonText={language.reset}
                setIOValue={props.setIOValue}
              />
              <Alarms
                title={language.generalAlarms}
                ioValues={locationEquipment1?.Other?.ioValues}
              />
              <Info
                img={PLC}
                title={language.boosterPLCStatus}
                statistic={PLCStateRouter(props.plcs?.plcs?.[2])}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[2], theme)}
              />
              <AlarmReset
                alarmData={locationEquipment1?.Other}
                setIOValue={props.setIOValue}
                buttonText={language.reset}
                title={language.alarmReset}
                resetWriteValue={1}
                buttonHeight="25px"
              />
              <AlarmReset
                alarmData={locationEquipment1?.Other}
                setIOValue={props.setIOValue}
                buttonText={language.reset}
                title={language.modbusReset}
                alarmKeys={{
                  masterAlarm: 'modbus_error',
                  masterAlarmReset: 'modbus_reset',
                }}
                resetWriteValue={1}
                buttonHeight="25px"
              />
            </InfoControlRow>
            <PumpsRowCustom height="140px">
              <div css={[css`height: 100%; width: 80px; display: flex; align-items: flex-end;`, styles.hiddenMobile]}>
                <Pipe horizontal capBefore capLabel={language.fromRiver} capLabelTop="-22px" capLabelLeft="5px" />
                <Statistic
                  float
                  border
                  label={locationEquipment1?.Other?.ioValues?.suction_pressure?.ioValueType?.units}
                  statistic={locationEquipment1?.Other?.ioValues?.suction_pressure?.value}
                  alertText={locationEquipment1?.Other?.ioValues.low_suction_pressure?.value ? language.lowSuctionPressure : null}
                  alertTop="4px"
                  alertLeft="40px"
                />
              </div>
              <Pump
                percent
                pipeAbove="right"
                pipeBelow="middle"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.P1}
                otherData={locationEquipment1?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment1.P1?.ioValues?.status?.id,
                    status: locationEquipment1.P1?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                ]}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment1.P1?.ioValues?.master_alarm,
                  locationEquipment1.P1?.ioValues?.high_seal_temperature,
                  locationEquipment1.P1?.ioValues?.low_amps,
                  locationEquipment1.P1?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P1?.ioValues?.current?.value,
                    label: locationEquipment1?.P1?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P1?.ioValues?.run_time?.value,
                    label: locationEquipment1?.P1?.ioValues?.run_time?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P1?.ioValues?.temperature?.value,
                    label: locationEquipment1?.P1?.ioValues?.temperature?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeBelow="middle"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.P2}
                otherData={locationEquipment1?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment1.P2?.ioValues?.status?.id,
                    status: locationEquipment1.P2?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                ]}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment1.P2?.ioValues?.master_alarm,
                  locationEquipment1.P2?.ioValues?.high_seal_temperature,
                  locationEquipment1.P2?.ioValues?.low_amps,
                  locationEquipment1.P2?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P2?.ioValues?.current?.value,
                    label: locationEquipment1?.P2?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P2?.ioValues?.run_time?.value,
                    label: locationEquipment1?.P2?.ioValues?.run_time?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P2?.ioValues?.temperature?.value,
                    label: locationEquipment1?.P2?.ioValues?.temperature?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeBelow="middle"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.P3}
                otherData={locationEquipment1?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment1.P3?.ioValues?.status?.id,
                    status: locationEquipment1.P3?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                ]}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment1.P3?.ioValues?.master_alarm,
                  locationEquipment1.P3?.ioValues?.high_seal_temperature,
                  locationEquipment1.P3?.ioValues?.low_amps,
                  locationEquipment1.P3?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P3?.ioValues?.current?.value,
                    label: locationEquipment1?.P3?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P3?.ioValues?.run_time?.value,
                    label: locationEquipment1?.P3?.ioValues?.run_time?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P3?.ioValues?.temperature?.value,
                    label: locationEquipment1?.P3?.ioValues?.temperature?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeBelow="left"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.P4}
                otherData={locationEquipment1?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment1.P4?.ioValues?.status?.id,
                    status: locationEquipment1.P4?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                ]}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment1.P4?.ioValues?.master_alarm,
                  locationEquipment1.P4?.ioValues?.high_seal_temperature,
                  locationEquipment1.P4?.ioValues?.low_amps,
                  locationEquipment1.P4?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P4?.ioValues?.current?.value,
                    label: locationEquipment1?.P4?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P4?.ioValues?.run_time?.value,
                    label: locationEquipment1?.P4?.ioValues?.run_time?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P4?.ioValues?.temperature?.value,
                    label: locationEquipment1?.P4?.ioValues?.temperature?.ioValueType?.units,
                  },
                ]}
              />
              <ValveConnect
                childBefore={(
                  <div css={css`display: flex; width: 65px; height: 15px; position: relative;`}>
                    <Pipe horizontal />
                    <div css={css`position: absolute;`}>
                      <div css={css`position: relative;`}>
                        <Statistic
                          float
                          border
                          label={locationEquipment1?.Other?.ioValues?.back_pressure?.ioValueType?.units}
                          statistic={locationEquipment1?.Other?.ioValues?.back_pressure?.value}
                        />
                      </div>
                    </div>
                  </div>
              )}
                percent
                readOnly
                reverseValve
                binary={false}
                IOValueKeys={{
                  position: 'position',
                }}
                percentTop="47px"
                percentLeft="4px"
                horizontal="above"
                nameTop="70px"
                nameLeft="8px"
                valveData={{ ...locationEquipment1?.CV1, name: 'CV1' }}
                setIOValue={props.setIOValue}
              />
              <ValveConnect
                percent
                showOnMobile
                pipeAbove="middle"
                percentTop="50px"
                percentLeft="-20px"
                nameTop="8px"
                nameLeft="50px"
                controls
                controlsTop="-10px"
                controlsLeft="85px"
                valveStateKeys={{
                  0: language.closed,
                  1: language.open,
                  2: language.inTravel,
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                alarms={[
                  locationEquipment1?.DV?.ioValues?.failed_to_close,
                  locationEquipment1?.DV?.ioValues?.failed_to_open,
                ]}
                greenImageKey={language.opened}
                yellowImageKey={language.inTravel}
                valveData={{ ...locationEquipment1?.DV, name: 'DV' }}
                setIOValue={props.setIOValue}
              />
              <div css={[css`height: 100%; width: fit-content; display: flex; justify-content: flex-start;`, styles.hiddenMobile]}>
                <div css={css`display: flex; width: 100%;`}>
                  <Statistic
                    border
                    label={locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                    statistic={locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.value}
                  />
                  <Statistic
                    border
                    label={locationEquipment1?.Other?.ioValues?.water_flow?.ioValueType?.units}
                    statistic={locationEquipment1?.Other?.ioValues?.water_flow?.value}
                  />
                  <Pipe horizontal capAfter />
                </div>
              </div>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          {/* <BasicRow styles={`min-width: fit-content; min-height: fit-content; @media only screen and (max-width: ${theme.mobileBreakpoint}px) { min-width: calc(100vw - 26px); max-width: calc(100vw - 26px);}`}> */}
          <LabeledContainer border backgroundColor={theme.backgroundColor} label={language.mainPumphouse}>
            <InfoControlRow>
              <MobileStatTable
                stats={[
                  {
                    description: language.dischargeWaterPressure,
                    label: locationEquipment2?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.ioValueType?.units,
                    stat: locationEquipment2?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.value,
                    alertText: locationEquipment2?.Other?.ioValues.high_discharge_pressure?.value ? language.highDischargePressure : null,
                  },
                ]}
              />
              <Info
                title={language.vaultTemp}
                statistic={locationEquipment2?.Other?.ioValues?.vault_temperature?.value}
                label={locationEquipment2?.Other?.ioValues?.vault_temperature?.ioValueType?.units}
                precision={1}
                alertText={locationEquipment1?.Other?.ioValues?.low_vault_temperature?.value ? language.lowTemp : ''}
                alertTop="-30px"
                alertLeft="4px"
              />
              <Alarms
                title={language.generalAlarms}
                ioValues={locationEquipment2?.Other?.ioValues}
              />
              <Info
                img={PLC}
                title={language.mainPLCStatus}
                statistic={PLCStateRouter(props.plcs?.plcs?.[1])}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[1], theme)}
              />
              <AlarmReset
                alarmData={locationEquipment2?.Other}
                setIOValue={props.setIOValue}
                buttonText={language.reset}
                title={language.alarmReset}
                resetWriteValue={1}
                buttonHeight="25px"
              />
              <AlarmReset
                alarmData={locationEquipment2?.Other}
                setIOValue={props.setIOValue}
                buttonText={language.reset}
                title={language.modbusReset}
                alarmKeys={{
                  masterAlarm: 'modbus_error',
                  masterAlarmReset: 'modbus_reset',
                }}
                resetWriteValue={1}
                buttonHeight="25px"
              />
            </InfoControlRow>
            <PumpsRowCustom height="90px">
              {/* <PipePlaceholder hidden /> */}
              <ValveConnect
                binary
                percent
                showOnMobile
                percentTop="50px"
                percentLeft="-20px"
                controls
                controlsTop="-10px"
                controlsLeft="50px"
                nameTop="-35px"
                nameLeft="-10px"
                pipeAbove="right"
                valveStateKeys={{
                  0: language.closed,
                  1: language.open,
                  2: language.inTravel,
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                alarms={[
                  locationEquipment2?.FV1?.ioValues?.failed_to_close,
                  locationEquipment2?.FV1?.ioValues?.failed_to_open,
                ]}
                greenImageKey={language.opened}
                yellowImageKey={language.inTravel}
                valveData={{ ...locationEquipment2?.FV1, name: 'FV1' }}
                setIOValue={props.setIOValue}
              />
              <ValveConnect
                binary
                percent
                showOnMobile
                percentTop="50px"
                percentLeft="-20px"
                controls
                controlsTop="-10px"
                controlsLeft="50px"
                nameTop="-35px"
                nameLeft="-10px"
                pipeAbove="middle"
                valveStateKeys={{
                  0: language.closed,
                  1: language.open,
                  2: language.inTravel,
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                alarms={[
                  locationEquipment2?.FV2?.ioValues?.failed_to_close,
                  locationEquipment2?.FV2?.ioValues?.failed_to_open,
                ]}
                greenImageKey={language.opened}
                yellowImageKey={language.inTravel}
                valveData={{ ...locationEquipment2?.FV2, name: 'FV2' }}
                setIOValue={props.setIOValue}
              />
              <ValveConnect
                binary
                percent
                showOnMobile
                percentTop="50px"
                percentLeft="-20px"
                controls
                controlsTop="-10px"
                controlsLeft="50px"
                nameTop="-35px"
                nameLeft="-10px"
                pipeAbove="middle"
                valveStateKeys={{
                  0: language.closed,
                  1: language.open,
                  2: language.inTravel,
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                alarms={[
                  locationEquipment2?.FV3?.ioValues?.failed_to_close,
                  locationEquipment2?.FV3?.ioValues?.failed_to_open,
                ]}
                greenImageKey={language.opened}
                yellowImageKey={language.inTravel}
                valveData={{ ...locationEquipment2?.FV3, name: 'FV3' }}
                setIOValue={props.setIOValue}
              />
              <ValveConnect
                binary
                percent
                showOnMobile
                percentTop="50px"
                percentLeft="-20px"
                controls
                controlsTop="-10px"
                controlsLeft="50px"
                nameTop="-35px"
                nameLeft="-10px"
                pipeAbove="middle"
                valveStateKeys={{
                  0: language.closed,
                  1: language.open,
                  2: language.inTravel,
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                alarms={[
                  locationEquipment2?.FV4?.ioValues?.failed_to_close,
                  locationEquipment2?.FV4?.ioValues?.failed_to_open,
                ]}
                greenImageKey={language.opened}
                yellowImageKey={language.inTravel}
                valveData={{ ...locationEquipment2?.FV4, name: 'FV4' }}
                setIOValue={props.setIOValue}
              />
              <div css={css`height: 100%; width: 220px; display: flex; justify-content: flex-start;`}>
                <div css={css`display: flex; flex-direction: column;`}>
                  <div css={css`display: flex; flex-direction: column; height: 15px;`}>
                    <Pipe horizontal />
                  </div>
                  <div css={css`display: flex; min-width: 60px; height: 100%; align-items: flex-start;`}>
                    <div css={css`display: flex; width: fit-content; height: 100%; margin-left: 18px;`}>
                      <Pipe />
                    </div>
                  </div>
                </div>
                <div css={[css`display: flex; width: 100%;`, styles.hiddenMobile]}>
                  <Statistic
                    float
                    border
                    label={locationEquipment2?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.ioValueType?.units}
                    statistic={locationEquipment2?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.value}
                    alertText={locationEquipment2?.Other?.ioValues.high_discharge_pressure?.value ? language.highDischargePressure : null}
                    alertTop="-25px"
                    alertLeft="-150px"
                  />
                  <Pipe horizontal capAfter capLabel={language.toBooster} capLabelTop="20px" capLabelLeft="-80px" />
                </div>
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="135px">
              <Pump
                percent
                pipeAbove="right"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P1}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment2.P1?.ioValues?.status?.id,
                    status: locationEquipment2.P1?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                ]}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment2.P1?.ioValues?.master_alarm,
                  locationEquipment2.P1?.ioValues?.low_amps,
                  locationEquipment2.P1?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P1?.ioValues?.current?.value,
                    label: locationEquipment2?.P1?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P1?.ioValues?.run_time?.value,
                    label: locationEquipment2?.P1?.ioValues?.run_time?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P1?.ioValues?.temperature?.value,
                    label: locationEquipment2?.P1?.ioValues?.temperature?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P2}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment2.P2?.ioValues?.status?.id,
                    status: locationEquipment2.P2?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                ]}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment2.P2?.ioValues?.master_alarm,
                  locationEquipment2.P2?.ioValues?.low_amps,
                  locationEquipment2.P2?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P2?.ioValues?.current?.value,
                    label: locationEquipment2?.P2?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P2?.ioValues?.run_time?.value,
                    label: locationEquipment2?.P2?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P3}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment2.P3?.ioValues?.status?.id,
                    status: locationEquipment2.P3?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                ]}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment2.P3?.ioValues?.master_alarm,
                  locationEquipment2.P3?.ioValues?.low_amps,
                  locationEquipment2.P3?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P3?.ioValues?.current?.value,
                    label: locationEquipment2?.P3?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P3?.ioValues?.run_time?.value,
                    label: locationEquipment2?.P3?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="left"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P4}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment2.P4?.ioValues?.status?.id,
                    status: locationEquipment2.P4?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                ]}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment2.P4?.ioValues?.master_alarm,
                  locationEquipment2.P4?.ioValues?.low_amps,
                  locationEquipment2.P4?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P4?.ioValues?.current?.value,
                    label: locationEquipment2?.P4?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P4?.ioValues?.run_time?.value,
                    label: locationEquipment2?.P4?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <div css={css`height: 100%; width: 220px; display: flex; justify-content: flex-start; @media only screen and (max-width: ${theme.mobileBreakpoint}px) { width: 100%; justify-content: center; align-items: center;}`}>
                <div css={css`display: flex; width: 100%; max-width: 60px; @media only screen and (max-width: ${theme.mobileBreakpoint}px) { max-width: fit-content; }`}>
                  <ValveConnect
                    minWidth="60px"
                    percent
                    binary
                    controls
                    showOnMobile
                // controlsTop="-60px"
                // controlsLeft="-50px"
                    controlsTop="-10px"
                    controlsLeft="85px"
                    percentTop="50px"
                    percentLeft="-20px"
                    nameTop="8px"
                    nameLeft="50px"
                    valveStateKeys={{
                      0: language.closed,
                      1: language.open,
                      2: language.inTravel,
                    }}
                    valveStateColorKeys={{
                      0: theme.offRed,
                      1: theme.onGreen,
                      2: theme.warning,
                    }}
                    IOValueKeys={{
                      opened: 'opened',
                      closed: 'closed',
                      open: 'open',
                      close: 'close',
                    }}
                    alarms={[
                      locationEquipment2?.DV?.ioValues?.failed_to_close,
                      locationEquipment2?.DV?.ioValues?.failed_to_open,
                    ]}
                    greenImageKey={language.opened}
                    yellowImageKey={language.inTravel}
                    valveData={{ ...locationEquipment2?.DV, name: 'DV' }}
                    setIOValue={props.setIOValue}
                  />
                </div>
                <div css={css`width: 60px;`} />
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="40px">
              <Pond
                title={language.river}
                color={theme.pipeColors.water}
              />
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        min-height: fit-content;
        max-height: fit-content;
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
      scrollbar-width: none; /* Firefox */
    `,
  };
};

MainScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

MainScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocations, setIOValue, fetchPLCs })(MainScreen);
