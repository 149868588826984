import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pipe,
  PipeCurve,
  Compressor,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  PumpsRowMedium,
  LabeledContainer,
} from 'isno/lib/components/layout';
import {
  Statistic,
  MobileStatTable,
} from 'isno/lib/components/displays';

import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';
import LoadingScreen from './LoadingScreen';

function CompressorScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(1),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before setting 'initRequestsComplete' to true. We use
    // this to make sure we have all of the neccesary data before generating our map.
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();

    return () => {
      clearTimeout(oneSecondIntervalId);
    };
  }, []);

  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  if (!locationEquipment1) { return <LoadingScreen />; }

  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Compressors">
            <PumpsRowMedium>
              <MobileStatTable
                stats={[
                  {
                    description: language.dischargeAirPressure,
                    stat: locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.value,
                    label: locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.ioValueType?.units,
                  },
                ]}
              />
              <Compressor
                readOnly
                pipeAbove="right"
                pipeColorAbove={theme.pipeColors.air}
                doublePipeBelowColors={[
                  theme.pipeColors.water,
                  theme.pipeColors.hotWater,
                ]}
                controlPositionTop="-45px"
                controlPositionLeft="70px"
                compressorData={locationEquipment1?.C1}
                setIOValue={props.setIOValue}
                stats={[
                  {
                    stat: locationEquipment1?.C1?.ioValues?.current?.value,
                    label: locationEquipment1?.C1?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C1?.ioValues?.run_time?.value,
                    label: locationEquipment1?.C1?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
              />
              <Compressor
                readOnly
                pipeAbove="middle"
                pipeColorAbove={theme.pipeColors.air}
                doublePipeBelowColors={[
                  theme.pipeColors.water,
                  theme.pipeColors.hotWater,
                ]}
                controlPositionTop="-45px"
                controlPositionLeft="70px"
                compressorData={locationEquipment1?.C2}
                setIOValue={props.setIOValue}
                stats={[
                  {
                    stat: locationEquipment1?.C2?.ioValues?.current?.value,
                    label: locationEquipment1?.C2?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C2?.ioValues?.run_time?.value,
                    label: locationEquipment1?.C2?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
              />
              <Compressor
                readOnly
                pipeAbove="middle"
                pipeColorAbove={theme.pipeColors.air}
                doublePipeBelowColors={[
                  theme.pipeColors.water,
                  theme.pipeColors.hotWater,
                ]}
                controlPositionTop="-45px"
                controlPositionLeft="70px"
                compressorData={locationEquipment1?.C3}
                setIOValue={props.setIOValue}
                stats={[
                  {
                    stat: locationEquipment1?.C3?.ioValues?.current?.value,
                    label: locationEquipment1?.C3?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C3?.ioValues?.run_time?.value,
                    label: locationEquipment1?.C3?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
              />
              <Compressor
                readOnly
                pipeAbove="middle"
                pipeColorAbove={theme.pipeColors.air}
                doublePipeBelowColors={[
                  theme.pipeColors.water,
                  theme.pipeColors.hotWater,
                ]}
                controlPositionTop="-45px"
                controlPositionLeft="70px"
                compressorData={locationEquipment1?.C4}
                setIOValue={props.setIOValue}
                stats={[
                  {
                    stat: locationEquipment1?.C4?.ioValues?.current?.value,
                    label: locationEquipment1?.C4?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C4?.ioValues?.run_time?.value,
                    label: locationEquipment1?.C4?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
              />
              <div css={[css`display: flex; width: 100%; height: 100%; max-width: 100px;`, styles.hiddenMobile]}>
                <Statistic
                  border
                  statistic={locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.value}
                  label={locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.ioValueType?.units}
                />
                <Pipe
                  color={theme.pipeColors.air}
                  horizontal
                  capAfter
                />
              </div>
            </PumpsRowMedium>
            <div css={css`width: 100%; display: flex; flex-direction: column;`}>
              <div css={css`display: flex;`}>
                <div css={css`width: 18px;`} />
                <div css={css`display: flex;`}>
                  <Pipe color={theme.pipeColors.water} />
                </div>
                <PipeCurve color={theme.pipeColors.hotWater} up />
              </div>
              <div css={css`display: flex;`}>
                <PipeCurve color={theme.pipeColors.water} up />
              </div>
            </div>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        min-height: fit-content;
        max-height: fit-content;
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
      scrollbar-width: none; /* Firefox */
    `,
  };
};

CompressorScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

CompressorScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(CompressorScreen);
