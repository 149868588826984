import React from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import snowFlake from 'isno/lib/static/images/snowFlakeWhite.png';

const LoadingScreen = (props) => {
  const theme = useTheme();
  const styles = stylesFromTheme(theme);

  return (
    <div css={styles.background}>
      <div css={styles.section}>
        <img src={snowFlake} css={styles.snowFlake} alt="snowFlake" />
        <div>
          {'  Connecting...'}
        </div>
      </div>
    </div>
  );
};

const stylesFromTheme = (theme) => {
  return {
    background: css`
        width: 100%;
        height: 100vh;
        font-family: sans-serif;
        font-size: 40px;
        color: #fff;
        text-align: center;
    `,
    section: css`
        background-color: #8098b3; //light grey blue
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: white;
    `,
    snowFlake: css`
        height: 75px;
        width: 75px;
        margin-right: 10px;
        animation: spin 4s linear infinite;
        @keyframes spinreverse {
            0%{ transform: rotate(0deg); }
            100%{ transform: rotate(-360deg); }
        }
        @keyframes spin {
            0%{ transform: rotate(0deg); }
            100%{ transform: rotate(360deg); }
        }
    `,
  };
};

export default LoadingScreen;
